/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext } from "react";
import StaggerOnView from "../../lib/StaggerOnView";
import { EridenContext } from "../../App";
import { useHistory } from "react-router-dom";
import Swiper from "react-id-swiper";
import { gsap } from "gsap";

import { Card } from "./Card";
import { withEridenContext } from "../../lib/withEridenContext";
//import { withExperienceContext } from "../../lib/withExperienceContext";
//import { BASE_URL } from "../../conf";

function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

class PersonBio extends React.Component {
  timeline = gsap.timeline({ paused: true });
  componentDidMount() {
    this.timeline.from(this.$text, {
      opacity: 0,
      y: 100,
      ease: "power4.out",
      duration: 1,
      stagger: 0.1,
      clearProps: "all",
    });
    this.timeline.play();
  }
  componentDidUpdate(prevProps) {
    if (this.props.person.i !== prevProps.person.i) {
      this.timeline.restart();
      this.timeline.play();
    }
  }
  $text = [];
  render() {
    const { toggle, person } = this.props;
    const { name, rank, bio } = person;
    return (
      <>
        <h1 ref={(ref) => this.$text.push(ref)} className="name">
          {name}
        </h1>
        <h3 ref={(ref) => this.$text.push(ref)} className="rank">
          {rank}
        </h3>
        <div
          ref={(ref) => this.$text.push(ref)}
          className="bio"
          dangerouslySetInnerHTML={{ __html: bio }}
        ></div>
        <button
          ref={(ref) => this.$text.push(ref)}
          onClick={() => {
            toggle(false);
          }}
          className="close-btn"
        >
          <img alt="X" src={require("../../assets/images/close.svg")} />
          CLOSE
        </button>
      </>
    );
  }
}
class Team extends React.Component {
  state = {
    activePerson: {},
    personActive: false,
    activeIndex: 0,
    slider: null,
  };
  changeText = (e) => {
    gsap.to(this.$inner, {
      opacity: 0,
      onComplete: async () => {
        if (e) {
          await this.setState({
            activePerson: e,
            personActive: true,
          });
        } else {
          await this.setState({
            activePerson: {},
            personActive: false,
          });
        }

        gsap.to(this.$inner, {
          opacity: 1,
        });
      },
    });
  };
  changeIndex = () => {
    this.setState({
      activePerson: {
        ...this.props.persons[this.state.slider.realIndex],
        i: this.state.slider.realIndex,
      },
      activeIndex: this.state.slider.realIndex,
    });
  };
  addEvents = () => {
    if (this.state.slider) {
      this.state.slider.on("slideChange", this.changeIndex);
    }
  };
  removeEvents = () => {
    if (this.state.slider) {
      this.state.slider.off("slideChange", this.changeIndex);
    }
  };
  componentWillUnmount() {
    this.removeEvents();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.slider !== prevState.slider && this.state.slider) {
      this.addEvents();
    }
  }
  render() {
    // return <div></div>;
    const { paragraphs = [], persons = [], title = "" } = this.props;
    const { personActive, activePerson } = this.state;
    return (
      <StaggerOnView className="about-intro team-block">
        <div className="container" data-overlay="TEAM">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h6 className="fancy-subtitle">{title}</h6>
              {!personActive && (
                <p className="consistent-p">{paragraphs[0].paragraph}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-6 offset-lg-3 to-front"
              ref={(ref) => (this.$team = ref)}
            >
              <div
                className="person-bio select-none"
                ref={(ref) => (this.$inner = ref)}
              >
                {personActive && (
                  <PersonBio person={activePerson} toggle={this.changeText} />
                )}
              </div>
            </div>

            <div data-cursor="swipe" className="col-lg-3">
              <Swiper
                getSwiper={(props) => {
                  this.setState({ slider: props });
                }}
                {...{
                  slidesPerView: 1,
                  pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                  },
                  grabCursor: true,
                  navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  },
                  renderNextButton: (props) => {
                    return (
                      <button
                        className="d-flex align-center swiper-button-next"
                        disabled={this.state.activeIndex === persons.length}
                      >
                        Next
                        <svg
                          width="16"
                          height="31"
                          viewBox="0 0 16 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: "1rem" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5116 15.5L0 0.756098L0.744186 0L16 15.5L0.744186 31L0 30.2439L14.5116 15.5Z"
                            fill="#D5222C"
                          />
                        </svg>
                      </button>
                    );
                  },
                  renderPrevButton: (props) => {
                    return (
                      <button
                        className="d-flex align-center swiper-button-prev"
                        disabled={!this.state.activeIndex}
                      >
                        <svg
                          width="16"
                          height="31"
                          viewBox="0 0 16 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "1rem" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.48837 15.5L16 30.2439L15.2558 31L-5.12876e-07 15.5L15.2558 -5.13768e-07L16 0.756096L1.48837 15.5Z"
                            fill="white"
                          />
                        </svg>
                        Prev
                      </button>
                    );
                  },
                }}
              >
                {persons.map((person, i) => {
                  return (
                    <div
                      data-cursor="swipe"
                      key={"pEopEl" + i}
                      className={`people-card`}
                    >
                      <div className="people-card_inner">
                        <div className="people-card_overlay">
                          <button
                            onClick={() => {
                              const { top } = offset(this.$inner);
                              window.scrollTo(0, top - 200);

                              this.changeText({ ...person, i });
                            }}
                            disabled={
                              personActive || this.state.activeIndex !== i
                            }
                          >
                            READ BIO
                          </button>
                          <img alt="tempAlt" src={person.images} />
                        </div>
                        <div className="person">
                          <h3>{person.name}</h3>
                          <h6>{person.rank}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </StaggerOnView>
    );
  }
}

class PushToBottom extends React.Component {
  maxHeight = window.innerHeight;
  _handleScroll = () => {
    const maxHeight =
      this.props.i % 2 ? window.innerHeight : window.innerHeight - 100;
    const multiplier = !(this.props.i % 2) ? 1.5 : 1;
    gsap.to(this.$wrapper, {
      y:
        window.pageYOffset * multiplier > maxHeight
          ? maxHeight
          : window.pageYOffset * multiplier,
      ease: "power4.out",
      duration: 1,
    });
  };
  componentDidMount() {
    window.addEventListener("scroll", this._handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll);
  }
  render() {
    return (
      <div
        ref={(ref) => (this.$wrapper = ref)}
        className={this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}

export default function About() {
  const context = useContext(EridenContext);
  const about = context.content?.about?.blocks || [];
  return (
    <>
      <div className="about-intro about-block">
        <div className="container" data-overlay="ABOUT">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h1 className="fancy-subtitle">{about[0]?.title}</h1>

              {about[0]?.paragraphs.map((para, i) => {
                return (
                  <p key={i + "paragRaph-0"} className="consistent-p">
                    {para.paragraph}
                  </p>
                );
              })}
            </div>{" "}
          </div>
        </div>
      </div>

      <div className="about-intro history-block">
        <div className="container" data-overlay="history">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h3 className="fancy-subtitle">{about[1]?.title}</h3>

              {about[1]?.paragraphs.map((para, i) => {
                return (
                  <p key={i + "paragRaph-1"} className="consistent-p">
                    {para.paragraph}
                  </p>
                );
              })}
            </div>{" "}
          </div>
        </div>
      </div>

      <div className="about-intro geo-block">
        <div className="container" data-overlay="geo">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h3 className="fancy-subtitle">{about[2]?.title}</h3>

              {about[2]?.paragraphs.map((para, i) => {
                return (
                  <p key={i + "paragRaph-2"} className="consistent-p">
                    {para.paragraph}
                  </p>
                );
              })}

              <div className="location-image-container">
                <img
                  alt="tempAlt"
                  src={require("../../assets/images/map.svg")}
                />
              </div>
            </div>{" "}
          </div>
        </div>
      </div>

      <div className="about-intro value-block">
        <div className="container" data-overlay="VALUE">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h3 className="fancy-subtitle">{about[3]?.title}</h3>

              {about[3]?.paragraphs.map((para, i) => {
                return (
                  <p key={i + "paragRaph-3"} className="consistent-p">
                    {para.paragraph}
                  </p>
                );
              })}
            </div>{" "}
          </div>
        </div>
      </div>

      <div className="container m-b-5 p-t-5 p-b-5 big-section no-v-space">
        <Team {...about[6]} {...about[4]} />
      </div>

      <StaggerOnView
        data-overlay="TEAM"
        className="container-big p-t-5 p-b-5 big-section no-v-space"
      >
        <StaggerOnView className="card-holder about-awards">
          {about[5]?.cards.map((card, i) => {
            return (
              <Card
                icon={require("../../assets/images/trophy.svg")}
                {...card}
                key={i + "card"}
              />
            );
          })}
        </StaggerOnView>
      </StaggerOnView>
      <NextPage to="/services" name="Services" />
    </>
  );
}

export const NextPage = withEridenContext(function ({ context, to, name }) {
  const history = useHistory();
  return (
    <>
      <div className="next" data-bg={name}>
        <a
          href={to}
          onClick={(e) => {
            e.preventDefault();
            if (context.transitioning) {
              return;
            }
            context.setTransitioning(true);
            const viewPort = document.querySelector(".viewport");
            gsap.to(viewPort, {
              duration: 1,
              opacity: 0,
              ease: "power4.inOut",
              onComplete() {
                setTimeout(() => {
                  history.push(to);
                  window.scrollTo(0, 0);
                  gsap.to(viewPort, {
                    duration: 1,
                    opacity: 1,
                    delay: 2,
                    ease: "power4.inOut",
                  });
                }, 100);
              },
            });
          }}
        >
          <h3>NEXT</h3>
          {name}
        </a>
      </div>
      <div className="quick-nav">
        <ul>
          {context.content?.menu?.menu.map((link) => {
            if (link.to === to || history.location.pathname.includes(link.to)) {
              return null;
            }
            return (
              <li key={link.name + "quickNav"}>
                <a
                  alt="Quick Menu Link"
                  href={link.to}
                  onClick={(e) => {
                    e.preventDefault();
                    if (context.transitioning) {
                      return;
                    }
                    context.setTransitioning(true);
                    const viewPort = document.querySelector(".viewport");
                    gsap.to(viewPort, {
                      duration: 1,
                      opacity: 0,
                      ease: "power4.inOut",
                      onComplete() {
                        setTimeout(() => {
                          history.push(link.to);
                          window.scrollTo(0, 0);
                          gsap.to(viewPort, {
                            duration: 1,
                            opacity: 1,
                            delay: 2,
                            ease: "power4.inOut",
                          });
                        }, 100);
                      },
                    });
                  }}
                >
                  <span>{link.name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
});
