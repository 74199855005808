import React, { Component } from "react";
import Inview from "react-inview-monitor";
import { gsap } from "gsap";

export default class StaggerOnView extends Component {
  state = {
    inView: false,
  };
  tl = gsap.timeline({ paused: true });
  componentDidMount() {
    const {
      stagger = 0.1,
      y = 100,
      x = 0,
      ease = "power4.out",
      opacity = 0,
      duration = 1,
    } = this.props;
    // console.log();
    if (this.props.children && this.props.children.length) {
      this.tl.from(this.wrapper._element.childNodes, {
        stagger,
        y,
        x,
        ease,
        opacity,
        duration,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.inView !== this.state.inView && this.state.inView) {
      this.tl.play();
    }
    if (this.props.children !== prevProps.children) {
    }
  }
  render() {
    const { className = "", style, children } = this.props;
    return (
      <Inview
        {...this.props}
        margin={100}
        style={style}
        classNameNotInView={className}
        classNameInView={className}
        onInView={() =>
          !this.state.inView ? this.setState({ inView: true }) : null
        }
        ref={(ref) => (this.wrapper = ref)}
      >
        {children}
      </Inview>
    );
  }
}
