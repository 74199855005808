import React, { Component } from "react";
import { withEridenContext } from "../../lib/withEridenContext";
import { BASE_URL } from "../../conf";
import { NextPage } from "../About";

class Contact extends Component {
  state = {
    mostViewed: {},
    result: null,
  };
  getMost = async () => {
    const { timers } = this.props.context;
    if (timers) {
      var sortable = [];
      for (var elem in timers) {
        sortable.push([elem, timers[elem]]);
      }

      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });
      sortable = sortable.reverse();
      const mostViewed = { name: sortable[0][0], time: sortable[0][1] };
      await this.setState({ mostViewed });
    }
  };

  async componentDidMount() {
    await this.getMost();
  }
  async componentDidUpdate(prevProps) {
    if (this.props.context.timers !== prevProps.context.timers) {
      await this.getMost();
    }
  }
  makeText = () => {
    let text = this.props.context.content.contact[this.state.mostViewed.name];
    if (text) {
      text = text.replace("{{seconds}}", this.state.mostViewed.time);
    } else {
      text = `
      Looks like you haven't checked out any of our services and our algorythms can't decide what to suggest to you.
      <br>
      <br>
      Basically, it means that we don't have enought Data.
      <br>
      <br>
      <b>We still encourage you to get in touch with us!</b>
      `;
    }

    return text;
  };
  render() {
    return (
      <>
        <div className=" contact-container">
          <div className="container m-t-5-md">
            <div className="row">
              <div className="col-lg-4">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const result = await fetch(
                      BASE_URL + "/wp-json/contact/v1/send",
                      {
                        method: "POST",
                        body: new FormData(e.target),
                      }
                    ).then((res) => res.json());
                    this.setState({
                      result: result,
                    });
                  }}
                  action=""
                >
                  <div className="two-col">
                    <div className="form-group">
                      <label htmlFor="">Name</label>
                      <input name="name" required type="text" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Company</label>
                      <input name="company" required type="text" />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="">Email Address</label>
                    <input name="email" required type="email" />
                  </div>
                  <div className="form-group">
                    <select name="service" required>
                      <option value="">Select Service</option>
                      {this.props.context.content.services.services.map(
                        (service) => (
                          <option
                            key={service.shortname.split(" ").join()}
                            value={service.shortname}
                          >
                            {service.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="comment"
                      required
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  {this.state.result && (
                    <div
                      className={`form-group alert alert-${
                        this.state.result.status ? "success" : "danger"
                      }`}
                    >
                      {this.state.result.message}
                    </div>
                  )}
                  <div className="form-group">
                    <button type="submit">SEND MESSAGE</button>
                  </div>
                </form>
              </div>

              <div className="col-lg-6 offset-lg-2 d-flex justify-center column">
                {/*<div className="make-text">
                  {this.state.mostViewed.name && (
                    <h6 className="text-primary">Advanced AI Algorithms</h6>
                  )}
                  <h1
                    dangerouslySetInnerHTML={{ __html: this.makeText() }}
                  ></h1>
                  </div>*/}
                <div className="location-info">
                  <h6 className="text-primary">LOCATION</h6>
                  <p>700 Colorado Blvd, Suite 607, Denver, CO 80206</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NextPage to="about" name="About" />
      </>
    );
  }
}

export default withEridenContext(Contact);
