import React, { Component } from "react";
import { withEridenContext } from "../../lib/withEridenContext";
import { NextPage } from "../About";
//import { BASE_URL } from "../../conf";

class Portfolio extends Component {
  render() {
    return (
      <>
        <div className="container big-section">
          <div className="row m-y-5">
            <div className="col-lg-4 portfolio-text">
              <h6 className="m-b-5 text-primary">
                {this.props.context?.content?.portfolio?.name}
              </h6>
              <h1>{this.props.context?.content?.portfolio?.description}</h1>
            </div>
            <div className="col-lg-6 offset-lg-2 d-flex align-center">
              <div className="clients">
                {this.props.context?.content?.portfolio?.past_clients?.clients.map(
                  (client) => {
                    return (
                      <div
                        key={"clIent" + client.image}
                        className="single_client"
                      >
                        <img alt="tempAlt" src={client.image} />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
        <NextPage to="rnd" name="Case Studies" />
      </>
    );
  }
}

export default withEridenContext(Portfolio);
