import React, { Component } from "react";
import { gsap } from "gsap";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";

export class NavIcon extends Component {
  tl = gsap.timeline({ paused: true });
  componentDidMount() {
    gsap.registerPlugin(MorphSVGPlugin);
    this.tl.to(
      this.outlineLeft,
      {
        morphSVG: this.fillInner,

        duration: 0.5,
        ease: "power4.inOut",
      },
      "-=0.5"
    );
    this.tl.to(
      this.outlineRight,
      {
        morphSVG: this.fillInner,
        duration: 0.5,
        ease: "power4.inOut",
      },
      "-=0.5"
    );
    this.tl.to(
      this.outlineInner,
      {
        morphSVG: this.fillInner,
        duration: 0.5,
        ease: "power4.inOut",
      },
      "-=0.5"
    );
    this.tl.play().reverse();
  }
  render() {
    return (
      <span
        onMouseEnter={() => this.tl.play()}
        onMouseLeave={() => this.tl.reverse()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="none"
          viewBox="0 0 80 80"
        >
          <path
            fill="url(#paint0_linear1)"
            ref={(ref) => (this.outlineRight = ref)}
            d="M18.002 50a8 8 0 11-.004-16.001A8 8 0 0118.002 50zm0-14.246a6.246 6.246 0 100 12.493 6.246 6.246 0 000-12.493z"
          ></path>
          <path
            fill="url(#paint1_linear1)"
            ref={(ref) => (this.outlineLeft = ref)}
            d="M18.002 16a8 8 0 11-.004-16.001A8 8 0 0118.002 16zm0-14.246a6.246 6.246 0 100 12.492 6.246 6.246 0 000-12.492z"
          ></path>
          <path
            fill="url(#paint2_linear1)"
            ref={(ref) => (this.outlineInner = ref)}
            d="M39.497 35A10.5 10.5 0 1150 24.5 10.511 10.511 0 0139.497 35zm0-18.71a8.205 8.205 0 108.205 8.21 8.214 8.214 0 00-8.205-8.21z"
          ></path>
          <path
            style={{ visibility: "hidden" }}
            ref={(ref) => (this.fillInner = ref)}
            fill="url(#paint5_linear1)"
            d="M55,0H21a6.52,6.52,0,0,0-4.61,1.91L10.73,7.52a1,1,0,0,1-.73.3H8.34a.52.52,0,0,1-.52-.52V.52A.52.52,0,0,0,7.3,0H.52A.52.52,0,0,0,0,.52V56.91a6.53,6.53,0,0,0,2.34,5l11.49,9.57A6.55,6.55,0,0,0,18,73H52.05a6.52,6.52,0,0,0,4.61-1.91l5.61-5.61a1,1,0,0,1,.73-.3h1.66a.52.52,0,0,1,.52.52v6.78a.52.52,0,0,0,.52.52h6.78a.52.52,0,0,0,.52-.52V16.09a6.51,6.51,0,0,0-2.35-5L59.17,1.51A6.5,6.5,0,0,0,55,0Zm9.13,62.57H8.86a1,1,0,0,1-1-1V11.47a1,1,0,0,1,1-1H64.14a1,1,0,0,1,1,1V61.53A1,1,0,0,1,64.13,62.57Z"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear1"
              x1="43.823"
              x2="47.639"
              y1="28.833"
              y2="21.158"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D32029"></stop>
              <stop offset="0.406" stopColor="#E9394D"></stop>
              <stop offset="0.979" stopColor="#EF5337"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear1"
              x1="2.377"
              x2="6.193"
              y1="28.821"
              y2="21.146"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D32029"></stop>
              <stop offset="0.406" stopColor="#E9394D"></stop>
              <stop offset="0.979" stopColor="#EF5337"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear1"
              x1="22.985"
              x2="26.801"
              y1="28.841"
              y2="21.166"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D32029"></stop>
              <stop offset="0.406" stopColor="#E9394D"></stop>
              <stop offset="0.979" stopColor="#EF5337"></stop>
            </linearGradient>
            <linearGradient
              id="paint3_linear1"
              x1="31.771"
              x2="54.951"
              y1="53.713"
              y2="7.094"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D32029"></stop>
              <stop offset="0.406" stopColor="#E9394D"></stop>
              <stop offset="0.979" stopColor="#EF5337"></stop>
            </linearGradient>
            <linearGradient
              id="paint4_linear1"
              x1="-1.958"
              x2="21.222"
              y1="37.003"
              y2="-9.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D32029"></stop>
              <stop offset="0.406" stopColor="#E9394D"></stop>
              <stop offset="0.979" stopColor="#EF5337"></stop>
            </linearGradient>
            <linearGradient
              id="paint5_linear1"
              x1="14.905"
              x2="38.109"
              y1="45.426"
              y2="-1.243"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D32029"></stop>
              <stop offset="0.406" stopColor="#E9394D"></stop>
              <stop offset="0.979" stopColor="#EF5337"></stop>
            </linearGradient>
          </defs>
        </svg>{" "}
      </span>
    );
  }
}
