import { gsap } from "gsap";
import React from "react";
import isMobile from "ismobilejs";

export class DragCursor extends React.Component {
  cursorTL = gsap.timeline({ paused: true });
  componentDidMount() {
    this.cursorTL.from(this.$cursor.childNodes, {
      scale: 0,
      duration: 0.2,
      opacity: 0,
      stagger: 0.1,
      ease: "power4.out",
      transformOrigin: "center"
    });
    this.cursorTL.from(
      this.$text.childNodes,
      {
        y: 100,
        duration: 0.2,
        opacity: 0,
        stagger: 0.05,
        ease: "power4.out",
        transformOrigin: "center"
      },
      "-=0.5"
    );
    window.addEventListener("mousemove", this._handleMouseMove);
  }
  componentWillUnmount() {
    window.removeEventListener("mousemove", this._handleMouseMove);
  }
  cursorAnim = e => {
    if (e) {
      this.cursorTL.play();
    } else {
      this.cursorTL.reverse();
    }
  };
  addEvents = () => {};
  _handleMouseMove = e => {
    if (e.target) {
      const target =
        typeof e.target.closest === "function"
          ? e.target.closest(`[data-cursor="swipe"]`)
          : null;
      this.cursorAnim(target);
      const { clientX: x, clientY: y } = e;
      gsap.to(this.$elem, {
        x: x - 30,
        y: y - 30,
        duration: 0.2,
        ease: "power4.out"
      });
    }
  };
  render() {
    return (
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: 100,
          pointerEvents: "none",
          translate: "transform(-50%, -50%)",
          display: isMobile().any ? "none" : "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
        ref={ref => (this.$elem = ref)}
      >
        <svg
          width="59"
          height="59"
          viewBox="0 0 59 59"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={ref => (this.$cursor = ref)}
        >
          <circle cx="29.5" cy="29.5" r="29" stroke="white" />
          <path d="M21 25L16 30L21 35" stroke="white" />
          <path d="M37 35L42 30L37 25" stroke="white" />
        </svg>
        <p
          style={{ overflow: "hidden" }}
          className="child-block"
          ref={ref => (this.$text = ref)}
        >
          <span>S</span>
          <span>W</span>
          <span>I</span>
          <span>P</span>
          <span>E</span>
        </p>
      </div>
    );
  }
}
