import React from "react";
import { ExperienceContext } from "../routes/index";

export const withExperienceContext = Component => {
  return props => {
    return (
      <ExperienceContext.Consumer>
        {context => {
          return (
            <Component {...props} context={{ ...context, ...props.context }} />
          );
        }}
      </ExperienceContext.Consumer>
    );
  };
};
