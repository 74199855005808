import * as THREE from "three";

export const OrbitControls = require("three-orbit-controls")(THREE);
export const colors = {
  dark: "#02000C",
  bigParticles: "#232323",
  primary: "#ec4245",
  white: "white",
  teal: "#4efdfe",
  black: "#000000"
};

export const pointsOnASphere = ({
  N = 10000,
  size = 25,
  position = { x: 0, y: 0, z: 0 },
  randomRotation = false
}) => {
  //var group = new THREE.Group();
  let positions = new Float32Array(N * 3);
  let inc = Math.PI * (3 - Math.sqrt(5));
  const offset = 2 / N;
  const geometry = new THREE.BufferGeometry();
  for (let i = 0; i < N; i++) {
    const phi = i * inc;
    const phioffset = i * offset - 1 + offset / 2;
    const r = Math.sqrt(1 - phioffset * phioffset);
    positions[3 * i] = size * Math.cos(phi) * r;
    positions[3 * i + 1] = size * phioffset;
    positions[3 * i + 2] = size * Math.sin(phi) * r + position.z;
  }
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

  return geometry;
};

export const pointsOnAPlane = ({
  N = 10000,
  size = 25,
  position = { x: 0, y: 0, z: 0 }
}) => {
  let positions = new Float32Array(N * 3);
  const geometry = new THREE.BufferGeometry();
  for (let i = 0; i < N; i++) {
    positions[3 * i] = size * Math.random() - size / 2;
    positions[3 * i + 1] = size * Math.random() - size / 2;
    positions[3 * i + 2] = position.z;
  }
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
  return geometry;
};

export async function FileLoader(file) {
  var loader = new THREE.FileLoader();
  return new Promise((resolve, reject) => {
    loader.load(
      // resource URL
      file,

      // onLoad callback
      function(data) {
        // output the text to the console
        resolve(data);
      },

      // onProgress callback
      function(xhr) {
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },

      // onError callback
      function(err) {
        reject(err);
      }
    );
  });
}
