import React from "react";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import ResizeObserver from "resize-observer-polyfill";
import isMobile from "ismobilejs";
import { linear } from "./linear";
import ScrollMouse from "../components/ScrollMouse";

export default class SmoothScroll extends React.Component {
  state = {
    height: window.innerHeight,
  };

  ro = new ResizeObserver((elements) => {
    for (let elem of elements) {
      const crx = elem.contentRect;
      this.setState({
        height: crx.height,
      });
      this._findRef(crx.height);
    }
  });

  componentDidMount() {
    if (!isMobile().any) {
      window.addEventListener("scroll", this.onScroll);
    }
    this.scrollText = new SplitText(this.scrollText, {
      type: "words,chars",
    });

    this.ro.observe(this.viewport);
  }

  componentDidUpdate(prevProps) {
    //let timeline1, timeline2;
    if (prevProps.open !== this.props.open) {
      //
      if (this.props.open) {
        gsap.to(this.viewport, {
          duration: 0.7,
          scale: 0.9,
          opacity: 0,
          ease: "power4.out",
        });
        gsap.to(this.scrollBar, {
          opacity: 0,
          ease: "power4.out",
          duration: 0.7,
        });
      } else {
        gsap.to(this.viewport, {
          delay: 2,
          scale: 1,
          duration: 0.7,
          opacity: 1,
          ease: "power4.out",
        });
      }
    }
  }
  _findRef = (stateHeight) => {
    if (this.spanRef) {
      const realHeight =
        (stateHeight ? stateHeight : this.state.height) - window.innerHeight;
      if (realHeight <= window.innerHeight) {
        gsap.to(this.scrollBar, {
          opacity: 0,
          delay: 1,
          ease: "power4.out",
          duration: 1,
        });
        return;
      } else {
        gsap.to(this.scrollBar, {
          opacity: 1,
          delay: 1,
          ease: "power4.out",
          duration: 1,
        });
      }

      const maxHeight = Math.round(realHeight) || 0;
      const height = linear(window.pageYOffset, 0, maxHeight, 0, 1);
      gsap.to(this.spanRef, {
        height: height * 100 + "%",
        transformOrigin: "center top",
        duration: 0.5,
        ease: "power4.out",
      });
    }
  };
  onScroll = () => {
    this._findRef();
    gsap.to(this.viewport, {
      y: -window.pageYOffset,
      duration: 1,
      ease: "power4.out",
    });
    gsap.to(this.p, {
      opacity: window.pageYOffset > 1 ? 0 : 1,
      duration: 1,
      ease: "power4.inOut",
    });
  };

  render() {
    if (isMobile().any) {
      return (
        <div className="viewport mobile" ref={(ref) => (this.viewport = ref)}>
          {this.props.children}
        </div>
      );
    }
    return (
      <>
        <ScrollMouse height={this.state.height} />
        <div ref={(ref) => (this.scrollBar = ref)} className="scrollIndicator">
          <span ref={(ref) => (this.spanRef = ref)}></span>
          <p ref={(ref) => (this.p = ref)}>SCROLL</p>
        </div>
        <div className="viewport" ref={(ref) => (this.viewport = ref)}>
          {this.props.children}
        </div>
        <div
          ref={(ref) => (this.fake = ref)}
          style={{
            height: this.state.height,
          }}
        />
      </>
    );
  }
}
