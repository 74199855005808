import React, { Component } from "react";
import * as THREE from "three";
import {
  colors,
  pointsOnASphere,
  FileLoader,
  pointsOnAPlane,
} from "../Background";
import ResizeObserver from "resize-observer-polyfill";
import {
  BokehEffect,
  EffectPass,
  EffectComposer,
  KernelSize,
  BloomEffect,
  RenderPass,
  BlendFunction,
  NoiseEffect,
} from "postprocessing";
import { gsap } from "gsap";
import { linear } from "../../lib/linear";
import { withRouter } from "react-router-dom";
import { withExperienceContext } from "../../lib/withExperienceContext";
import { gpuDetect } from "../../lib/gpuSniff";
import { getRandomArbitrary } from "../../routes/Services";
import { withEridenContext } from "../../lib/withEridenContext";

const POSITIONCoords = {
  desktop: [
    [-30, 15, 0],
    [-35, -15, 0],
    [10, -10, 0],
    [30, 14, 0],
    [35, -20, 0],
  ],
  mobile: [
    [getRandomArbitrary(6, 10), 30, -20],
    [getRandomArbitrary(6, 10), 15, -20],
    [getRandomArbitrary(6, 10), 0, -20],
    [getRandomArbitrary(6, 10), -15, -20],
    [getRandomArbitrary(6, 10), -30, -20],
  ],
};

const startPosition = [0, 0, -20];

// function makePointsBetween(positions) {
//   const LinePositions = [];
//   for (let index = 0; index < positions.length - 2; index++) {
//     LinePositions.push([
//       new THREE.Vector3(...positions[index]),
//       new THREE.Vector3(...positions[index + 1]),
//       new THREE.Vector3(...positions[index + 2])
//     ]);
//   }
//   return LinePositions;
// }

const POSITION =
  window.innerWidth < 1200 ? POSITIONCoords.mobile : POSITIONCoords.desktop;

// function clamp(num, min, max) {
//   return Math.min(Math.max(num, min), max);
// }

const GPU = gpuDetect();

// function hotSpot(evt = Event, rect, hotspotsize = 0) {
//   const { left, top, width, height } = rect;
//   var hotspot = hotspotsize ? hotspotsize : 0;
//   var x = (evt.pageX - left - hotspot) / width,
//     y = (evt.pageY - top - hotspot) / height;

//   x = clamp(x, 0, 1) * 2;
//   y = clamp(y, 0, 1) * 2;
//   x = x - 1;
//   y = y - 1;
//   x = -(x * x - 1);
//   y = -(y * y - 1);
//   return {
//     x: x,
//     y: y
//   };
// }

class InteractionParticles extends Component {
  state = { position: [], positionIndex: null, click: false };
  constructor(props) {
    super(props);
    this._throttledMouseMove = this._throttledMouseMove.bind(this);
  }
  ro = new ResizeObserver((elements) => {
    this._handleResize();
  });
  addComposer = (render = true) => {
    this.composer = new EffectComposer(this.renderer);
    this.bookehEffectIn = new BokehEffect({
      focus: 0.88,
      dof: 0.01,
      aperture: 0.05,
    });
    const noiseEffect = new NoiseEffect({
      blendFunction: BlendFunction.COLOR_DODGE,
    });
    noiseEffect.blendMode.opacity.value = 0.1;
    const bloomEffect = new BloomEffect({
      blendFunction: BlendFunction.ADD,
      kernelSize: KernelSize.HUGE,
      luminanceThreshold: 0,
      luminanceSmoothing: 0.075,
      height: 200,
    });
    const effectPass = new EffectPass(
      this.camera,
      bloomEffect,
      this.bookehEffectIn
      // noiseEffect
    );
    // const blurPass = new EffectPass(this.camera, );
    effectPass.renderToScreen = true;
    this.renderPass2 = new RenderPass(this.scene2, this.camera2);
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);
    this.composer.addPass(effectPass);
  };
  addBigParticles = async () => {
    const geometry = pointsOnAPlane({
      size: 750,
      N: GPU.any ? 5000 : 5000 / 2,
      position: {
        z: -250,
      },
    });
    const { bigFragment, bigVertex } = await this.setMaterials();
    this.bigParticlesMaterial = new THREE.ShaderMaterial({
      wireframe: false,
      side: THREE.DoubleSide,
      transparent: true,
      extensions: {
        derivatives: "#extensions GL_OES_standard_derivatives : enable",
      },
      uniforms: {
        hover: {
          type: "f",
          value: 0,
        },
        distortion: {
          value: 20,
        },
        uMouse: {
          type: "v2",
          value: new THREE.Vector2(1, 1),
        },
        time: { type: "f", value: this.time },
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1),
        },
        size: { value: 10 },
        scale: { value: 1 },
        color: { value: new THREE.Color(colors.bigParticles) },
      },
      vertexShader: bigVertex,
      fragmentShader: bigFragment,
    });
    this.bigPoints = new THREE.Points(geometry, this.bigParticlesMaterial);
    this.scene.add(this.bigPoints);
  };
  addParticles = async (x, y, z) => {
    const geometry = pointsOnASphere({
      size: 5,
      N: GPU.any ? 5000 : 5000 / 2,
      randomRotation: true,
    });
    geometry.rotateX(Math.random());
    geometry.rotateY(Math.random());
    geometry.rotateZ(Math.random());
    const { innerVertex, innerFragment } = await this.setMaterials();
    const randomness = getRandomArbitrary(0.191239019023, 1.8178237821378);

    const material = new THREE.ShaderMaterial({
      wireframe: false,
      side: THREE.DoubleSide,
      extensions: {
        derivatives: "#extensions GL_OES_standard_derivatives : enable",
      },
      uniforms: {
        hover: {
          type: "f",
          value: 0,
        },
        random: {
          type: "f",
          value: randomness,
        },
        uMouse: {
          type: "v2",
          value: new THREE.Vector2(1, 1),
        },
        time: { type: "f", value: this.time },
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1),
        },
        size: { value: 10 },
        scale: { value: 1 },
        color: { value: new THREE.Color(colors.primary) },
      },
      vertexShader: innerVertex,
      fragmentShader: innerFragment,
    });
    const pointsInner = new THREE.Points(geometry, material);
    pointsInner.position.set(x, y, z);
    pointsInner.updateWorldMatrix(true);
    pointsInner.geometry.boundingBox = null;
    pointsInner.geometry.computeBoundingSphere();
    return pointsInner;
  };
  setMaterials = async () => {
    const innerVertex = await FileLoader(
      require("../../assets/shaders/clickable/siteVertex.glsl")
    );
    const innerFragment = await FileLoader(
      require("../../assets/shaders/clickable/siteFragment.glsl")
    );
    const bigVertex = await FileLoader(
      require("../../assets/shaders/clickable/bigVertex.glsl")
    );
    const bigFragment = await FileLoader(
      require("../../assets/shaders/clickable/bigFragment.glsl")
    );
    return {
      innerFragment,
      innerVertex,
      bigVertex,
      bigFragment,
    };
  };
  positions = POSITION;
  addPoints = async () => {
    const points = await Promise.all(
      new Array(5).fill().map((elem) => {
        return async (x, y, z) => {
          const inner = await this.addParticles(x, y, z);

          inner.updateMatrixWorld(true);
          return [inner];
        };
      })
    );
    this.points = await Promise.all(
      points.map(async (elem, i) => await elem(...this.positions[i]))
    );
    this.points = this.points.map((points) => {
      const inner = points[0];
      const openTimeline = gsap.timeline({ paused: true });
      openTimeline.to(inner.material.uniforms.hover, {
        value: 2,
        ease: "power4.inOut",
        onComplete() {
          inner.isOpenPlaying = false;
        },
        onStart() {
          inner.isOpenPlaying = true;
        },
        onReverseComplete() {
          inner.isOpenPlaying = false;
        },
        duration: 0.5,
      });
      inner.openTimeline = openTimeline;
      const closeTimeline = gsap.timeline({ paused: true });

      closeTimeline.to(inner.material.uniforms.hover, {
        value: -10,
        ease: "back.inOut(2)",
        onComplete() {
          inner.isClosePlaying = false;
        },
        onStart() {
          inner.isClosePlaying = true;
        },
        onReverseComplete() {
          inner.isClosePlaying = false;
        },
        duration: 1.5,
      });
      inner.closeTimeline = closeTimeline;
      return inner;
    });
    this.scene.add(...this.points.flat());
  };

  async componentDidMount() {
    const width = this.mount.clientWidth,
      height = this.mount.clientHeight;
    this.scene = new THREE.Scene();
    this.scene2 = new THREE.Scene();
    const mesh = new THREE.Mesh(
      new THREE.SphereGeometry(50, 5, 5),
      new THREE.MeshBasicMaterial({
        color: new THREE.Color(colors.primary),
        wireframe: false,
      })
    );
    mesh.position.set(0, 0, -500);
    this.scene2.add(mesh);
    this.camera = new THREE.PerspectiveCamera(50, width / height, 1, 10000);
    this.camera2 = new THREE.PerspectiveCamera(50, width / height, 1, 10000);
    // this.controls = new OrbitControls(this.camera);
    await this.addPoints();
    // await this.makeLinesBetween();
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    this.renderer = new THREE.WebGLRenderer({
      antialias: GPU.any ? true : false,
      alpha: false,
    });
    this.raycaster = new THREE.Raycaster();
    this.renderer.setClearColor(new THREE.Color(colors.dark), 1);
    this.renderer.setSize(width, height);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setPixelRatio(GPU.any ? window.devicePixelRatio : 1);
    //this.addBigParticles();
    this.addComposer();
    this.animate();
    this.addEvents();
    this.props.context.setShaders();
    this.mount.appendChild(this.renderer.domElement);
    gsap.to(this.camera.position, {
      duration: 3,
      ease: "power4.out",
      z: 50,
    });
    this.changePosition();
  }
  addEvents = () => {
    window.addEventListener("mousemove", this._throttledMouseMove);
    window.addEventListener("scroll", this._handleScroll);
    window.addEventListener("mousedown", this._handleMouseDown);
    this.ro.observe(this.mount);
  };
  removeEvents = () => {
    window.removeEventListener("mousemove", this._throttledMouseMove);
    window.removeEventListener("scroll", this._handleScroll);
    window.removeEventListener("mousedown", this._handleMouseDown);
    if (this.ro) {
      this.ro.unobserve(this.mount);
    }
  };

  _handleMouseDown = (e) => {
    this.setState({
      click: true,
    });
    setTimeout(() => {
      this.setState({ click: false });
    }, 200);
  };
  getScreenCoordinates = () => {
    const Return = [];
    this.points.forEach((point) => {
      const tempV = new THREE.Vector3();
      point.getWorldPosition(tempV);
      tempV.project(this.camera);
      // convert the normalized position to CSS coordinates
      const x = (tempV.x * 0.5 + 0.5) * window.innerWidth;
      const y = (tempV.y * -0.5 + 0.5) * window.innerHeight;
      // move the elem to that position
      Return.push({ x, y });
    });

    return Return;
  };
  componentWillUnmount() {
    this.removeEvents();
  }
  _handleResize = () => {
    const width = this.mount.clientWidth,
      height = this.mount.clientHeight;
    this.renderer.setSize(width, height);
    this.composer.setSize(width, height);
    const coords = this.getScreenCoordinates();
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.props.context.functions.setScreenCoordinates(coords);
  };
  time = 0;
  clock = new THREE.Clock();
  previousIntersection = null;
  animateParticles = (intersects) => {
    let uuid = null;
    for (let i = 0; i < intersects.length; i++) {
      uuid = intersects[i].object.uuid;
    }
    this.points.flat().forEach((point) => {
      if (!this.props.context.state.navbar) {
        point.openTimeline.reverse();

        return;
      }
      if (uuid && uuid === point.uuid) {
        if (point.openTimeline && !point.openTimeline.isActive()) {
          point.openTimeline.play();
        }
        return;
      }
      point.openTimeline.reverse();
    });
  };
  animate = () => {
    requestAnimationFrame(this.animate);
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.time += 0.0001;
    this.points.flat().forEach((point, i) => {
      // point.material
      point.geometry.boundingBox = null;
      //   point.material.uniforms.hover.value = 0;
      point.material.uniforms.time.value = this.time;
      point.material.uniforms.scale.value = linear(
        this.camera.position.z,
        0,
        75,
        1,
        0.5
      );
      point.updateMatrixWorld(true);
    });
    var intersects = this.raycaster.intersectObjects(this.scene.children);
    this.animateParticles(intersects);
    if (this.bigParticlesMaterial) {
      this.bigParticlesMaterial.uniforms.time.value = this.time;
      // this.bigPoints.rotation.set(
      //   this.time / 1000,
      //   this.time / 1000,
      //   this.time / 1000
      // );
      this.bigParticlesMaterial.uniforms.scale.value = linear(
        this.camera.position.z,
        0,
        75,
        1,
        0
      );
    }
    this.composer.render(this.clock.getDelta());
  };
  cancel = () => {
    cancelAnimationFrame(this.animate);
  };
  mouse = new THREE.Vector2(1, 1);
  changePosition = () => {
    const p = this.getCurrentPosition();
    this.setState({
      position: p,
    });
  };
  _throttledMouseMove = (e) => {
    // CENTER HOTSPOT
    // const { x, y } = hotSpot(e, this.rect, 10);
    // this.mouse.x = x;
    // this.mouse.y = y;
    // SOME ROTATION
    // gsap.to([this.points.rotation, this.pointsInner.rotation], {
    //   x: e.clientY / window.innerWidth,
    //   y: e.clientX / window.innerHeight,
    //   ease: "power4.out",
    //   duration: 1
    // });
    //DEFAULT
    this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
    if (this.innerMaterial) {
      this.innerMaterial.uniforms.uMouse.value = this.mouse;
      this.pointMaterial.uniforms.uMouse.value = this.mouse;
    }
  };

  getCurrentPosition = () => {
    const p = this.positions;
    const { pathname = "" } = this.props.location;
    const routes = [
      "about",
      "services",
      "portfolio",
      //"rnd",
      "case-studies",
      "contact",
    ];
    if (this.props.context.state.navbar) {
      gsap.to(this.bookehEffectIn?.blendMode.opacity, {
        value: 0,
        duration: 3,
        ease: "power4.inOut",
      });
      return window.innerWidth < 1200 ? [0, -2, 90] : [0, 0, 75];
    } else {
      if (pathname === "/") {
        return startPosition;
      }
      for (let i in routes) {
        if (pathname.includes(routes[i])) {
          gsap.to(this.bookehEffectIn.blendMode.opacity, {
            value: 1,
            duration: 3,
            ease: "power4.inOut",
          });
          this.setState({ positionIndex: parseInt(i) });
          const newPos = p[i];
          // newPos[1] = newPos[1] - y;
          newPos[2] = window.innerWidth < 1200 ? -15 : 5;
          return newPos;
        }
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.position) {
      if (prevState.position !== this.state.position) {
        if (this.tween) {
          this.tween.kill();
        }
        setTimeout(() => {
          const coords = this.getScreenCoordinates();
          this.props.context.functions.setScreenCoordinates(coords);
        }, 2000);
        this.tween = gsap.to(this.camera.position, {
          x: this.state.position[0],
          y: this.state.position[1],
          z: this.state.position[2],
          onStart: () => this.props.context.setTransitioning(true),
          onComplete: () => this.props.context.setTransitioning(false),
          duration: 3,
          ease: "power4.inOut",
        });
      }
    }
    if (
      prevProps.location.pathname.split("/").filter(Boolean)[0] !==
      this.props.location.pathname.split("/").filter(Boolean)[0]
    ) {
      this.changePosition();
    }
    if (prevProps.context.state.navbar !== this.props.context.state.navbar) {
      this.changePosition();
    }
  }

  _handleScroll = () => {
    const final = Math.round(document.body.offsetHeight / 1000);
    const y = linear(
      window.pageYOffset,
      0,
      document.body.offsetHeight - window.innerHeight,
      0,
      final
    );
    if (y && this.state.position) {
      gsap.to(this.camera.position, {
        y: this.state.position[1] - y,
        duration: 1,
        ease: "power4.out",
      });
    }
  };
  _onClick = () => {
    if (this.state.position[2] < 10) {
      this.setState({ position: [0, 0, 75] });
      return;
    }
    const i = 0;
    const newPos = this.positions[i];
    newPos[2] = 7;
    this.setState({
      position: newPos,
    });
  };
  render() {
    return (
      <>
        <div
          className="interactionParticles_overlay"
          style={{ opacity: this.props.context.state.navbar ? 0 : 1 }}
        ></div>
        <div
          ref={(ref) => (this.mount = ref)}
          className="interactionParticles"
        ></div>
      </>
    );
  }
}

export default withEridenContext(
  withExperienceContext(withRouter(InteractionParticles))
);
