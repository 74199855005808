import React, { Component } from "react";

import { withExperienceContext } from "../../lib/withExperienceContext";
import { withEridenContext } from "../../lib/withEridenContext";

class Intro extends Component {
  state = {
    unmounting: false,
  };
  componentDidMount() {
    if (this.props.context.animationDone) {
      this.props.context.functions.toggleNavbar(true);
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.context.animationDone !== this.props.context.animationDone &&
      this.props.context.animationDone
    ) {
      this.timeout = setTimeout(() => {
        if (this.props.location.pathname === "/" && !this.state.unmounting) {
          this.props.context.functions.toggleNavbar(true);
          //console.log("toggles stuff");
        }
      }, 100);
    }
    // console.log(diff(prevProps.context, this.props.context));
  }
  componentWillUnmount() {
    this.setState({ unmounting: true });
    clearTimeout(this.timeout);
    this.props.context.functions.toggleNavbar(false);
  }
  render() {
    return (
      <>
        <div style={{ display: "none" }}>nothing to see here</div>
      </>
    );
  }
}

export default withEridenContext(withExperienceContext(Intro));
