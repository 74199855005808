import React, { Component } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

export class LinkItem extends Component {
  tl = gsap.timeline({ paused: true });
  openTL = gsap.timeline({ paused: true });
  state = {
    open: false
  };
  componentDidMount() {
    this.tl.from(this.description, {
      y: 20,
      duration: 0.5,
      opacity: 0,
      ease: "power4.out",
      clearProps: "all"
    });
  }
  render() {
    const { link, i, pushTo, coordinates = { x: 0, y: 0 } } = this.props;

    return (
      <li
        className="navbar-nav_link"
        data-big
        style={{
          transform: `translate(${coordinates.x}px, ${coordinates.y}px)`
        }}
        onMouseEnter={() => this.tl.play()}
        onMouseLeave={e => this.tl.reverse()}
      >
        <Link
          to={link.to}
          ref={ref => (this.linkRef = ref)}
          onClick={e => {
            e.preventDefault();
            if (!this.props.disabled) {
              window.scrollTo(0, 0);
              pushTo(link.to);
            }
          }}
        >
          <span className="num">0{i + 1}</span>
          {/* <Particles nav open={open} onClick={() => pushTo(link.to)} /> */}
          <span className="name">
            {link.name}
            <span ref={ref => (this.description = ref)} className="description">
              {link.description}
            </span>
          </span>
        </Link>
      </li>
    );
  }
}
