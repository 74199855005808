import React from "react";
import { EridenContext } from "../App";

export const withEridenContext = Component => {
  return props => {
    return (
      <EridenContext.Consumer>
        {context => {
          return (
            <Component {...props} context={{ ...context, ...props.context }} />
          );
        }}
      </EridenContext.Consumer>
    );
  };
};
