import React from "react";
import { gsap } from "gsap";
import { withEridenContext } from "./lib/withEridenContext";
import SplitText from "gsap/SplitText";
import ScrambleTextPlugin from "gsap/ScrambleTextPlugin";
import { getRandomArbitrary } from "./routes/Services";

function makeNumbers(x, numbers = 3) {
  const length = x.toString().length;
  const zeroCount = numbers - length;
  let retVal = x.toString();
  for (let i = 0; i < zeroCount; i++) {
    retVal = "0" + retVal;
  }
  return retVal;
}

class Loading extends React.Component {
  state = {
    progress: 0,
    animationComplete: false,
    loadingComplete: false,
    color: "#ffffff",
  };
  progress = {
    value: 0,
    color: "#ffffff",
  };
  componentDidMount() {
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrambleTextPlugin);
    this.initialTween = gsap.to(this.progress, {
      value: 100,
      color: "#d5222c",
      duration: 7,
      onUpdate: () => {
        this.setState({
          progress: makeNumbers(Number(this.progress.value).toFixed(0)),
          color: this.progress.color,
        });
      },
      ease: "power1.inOut",
    });
    const firstLine = new SplitText(this.firstLine, {
      type: "words,chars",
    });
    const secondLine = new SplitText(this.secondLine, {
      type: "words,chars",
    });
    const tagline = new SplitText(this.tagline, {
      type: "words,chars",
    });

    gsap.from(tagline.chars, {
      // scrambleText: {
      //   chars: "lowerCase"
      // },
      opacity: 0,
      stagger: 0.01,
      duration: 3,
    });

    this.secondTween = gsap.from([...firstLine.chars, ...secondLine.chars], {
      opacity: 0,
      // scrambleText: {
      //   chars: "lowerCase"
      // },
      onComplete: () => {
        this.setState({ animationComplete: true });
      },
      delay: (e) => {
        const delay = (e * getRandomArbitrary(1, 5)) / 500;
        return delay;
      },
      duration: 0.5,
      rotationX: 20,
      // scaleY: 2,
      transformOrigin: "center center",
      ease: "power4.out",
      stagger: {
        amount: 2,
        each: "random(50,10000)",
      },
    });
  }
  animateOut = () => {
    this.initialTween.kill();
    this.secondTween.kill();
    gsap.to(this.progress, {
      value: 100,
      duration: 0.5,
      onUpdate: () => {
        this.setState({
          progress: makeNumbers(Number(this.progress.value).toFixed(0)),
        });
      },
      onComplete: () => {
        this.props.context.animationComplete(true);
        gsap.to(this.wrapper, {
          opacity: 0,
          ease: "power4.inOut",
          duration: 1,
        });
      },
      ease: "power4.out",
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.context.loaded.content !== this.props.context.loaded.content &&
      prevProps.context.loaded.shaders !== this.props.context.loaded.shaders
    ) {
      if (!this.state.loadingComplete) {
        this.setState({
          loadingComplete: true,
        });
      }
    }
    if (
      this.state.loadingComplete &&
      prevState.animationComplete !== this.state.animationComplete &&
      this.state.animationComplete
    ) {
      setTimeout(() => {
        this.animateOut();
      }, 2000);
    }
  }
  render() {
    return (
      <>
        <div className="loading-inner" ref={(ref) => (this.wrapper = ref)}>
          <div className="wrapper" ref={(ref) => (this.animationWrapper = ref)}>
            <div className="animationWrapper" style={{ perspective: "0" }}>
              {/* <div className="loading_logo">
              <img src={require("./assets/images/logo.svg")} />
            </div> */}
              <div className="whiteSpace">
                <div className="line">
                  <span ref={(ref) => (this.firstLine = ref)} id="firstLine">
                    Both fear and curiosity are intertwining, giving rise to
                    smarter-than-human <b>intelligence</b> that has the ability
                    to make or break a business.
                  </span>
                </div>
                <div className="line">
                  <span ref={(ref) => (this.secondLine = ref)} id="secondLine">
                    Can your <b>business</b> withstand this change?
                  </span>
                </div>
              </div>
            </div>
            <h1 className="progressNum" style={{ color: this.state.color }}>
              {this.state.progress}
            </h1>
          </div>
        </div>
      </>
    );
  }
}

export default withEridenContext(Loading);
