import React, { Component } from "react";
import { withEridenContext } from "../../../lib/withEridenContext";
//import { BASE_URL } from "../../../conf";

class InnerPortfolio extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="row m-y-5">
            <div className="col-md-4">
              <h6 className="m-b-5">
                {this.props.context?.content?.portfolio?.past_clients?.name}
              </h6>
              <h1>
                {
                  this.props.context?.content?.portfolio?.past_clients
                    ?.description
                }
              </h1>
            </div>
            <div className="col-md-4 offset-md-2">
              {this.props.context?.content?.portfolio?.past_clients?.clients.map(
                (client) => {
                  return <img alt="tempAlt" src={client.image} />;
                }
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withEridenContext(InnerPortfolio);
