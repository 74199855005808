import React, { createContext } from "react";
import Routes from "./routes";
import { gsap } from "gsap";
import Loading from "./Loading";

import { BASE_URL } from "./conf";

export const EridenContext = createContext({});

function timeout(ms, promise) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject("timeout");
    }, ms);
    promise.then(resolve, reject);
  });
}

function ErrorThing() {
  return (
    <div className="full-page d-flex h-100vh column justify-center">
      <div className="container">
        <p className="text-center">
          There seems to be an error going on. <b>Please hold tight,</b> while
          we're fixing it.
        </p>
      </div>
    </div>
  );
}

class App extends React.Component {
  tl = gsap.timeline({ paused: true });

  state = {
    timers: null,
    loaded: {
      content: false,
      shaders: false
    },
    hasErorr: false,
    animationComplete: false,
    mounted: false,
    content: {},
    transitioning: false
  };

  animationComplete = e => {
    this.setState({
      animationComplete: e ? e : true
    });
  };

  setShaders = () => {
    this.setState({
      loaded: {
        ...this.state.loaded,
        shaders: true
      }
    });
  };
  setTimer = async ({ name, time }) => {
    await this.setState({
      timers: {
        ...this.state.timers,
        [name]: time
      }
    });
  };

  componentDidCatch(error) {
    this.setState({
      hasError: true
    });
  }

  setTransitioning = e => this.setState({ transitioning: e });

  getData = async () => {
    const pages = [
      "about-us",
      "services",
      "rnd",
      "portfolio",
      "contact",
      "menu"
    ];
    try {
      const results = await timeout(
        7000,
        Promise.all(
          pages.map(async page => {
            return await fetch(BASE_URL +'/'+ page, {
              method: "GET",
              mode: "cors"
            }).then(res => res.json());
          })
        ).catch(e => {
          throw new Error("error");
        })
      );
      // await delay(20000);

      return {
        about: results[0],
        services: results[1],
        rnd: results[2],
        portfolio: results[3],
        contact: results[4],
        menu: results[5]
      };
    } catch (e) {
      return {
        about: null,
        services: null,
        rnd: null,
        portfolio: null,
        contact: null
      };
    }
  };
  async componentDidMount() {
    this.tl.to(this.mainContent, {
      display: "none",
      opacity: 0,
      duration: 0
    });
    this.tl.to(this.loadingContent, {
      scaleY: 0,
      transformOrigin: "top center",
      duration: 1,
      ease: "power4.out"
    });
    this.tl.to(
      this.mainContent,
      {
        display: "block",
        opacity: 1,
        onComplete: () => {
          this.setState({
            mounted: true
          });
        },
        ease: "power4.out"
      },
      "-=1"
    );
    const content = await this.getData();
    this.setState({
      content,
      loaded: {
        content: true
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.animationComplete !== prevState.animationComplete &&
      this.state.animationComplete
    ) {
      setTimeout(() => {
        this.tl.play();
      }, 1000);
    }
  }
  render() {
    const {
      content,
      mounted,
      loaded,
      timers,
      animationComplete,
      transitioning,
      hasError
    } = this.state;
    if (hasError) {
      return <ErrorThing />;
    }
    return (
      <EridenContext.Provider
        value={{
          content,
          setTimer: this.setTimer,
          timers,
          loaded,
          animationComplete: this.animationComplete,
          animationDone: animationComplete,
          setShaders: this.setShaders,
          setTransitioning: this.setTransitioning,
          transitioning
        }}
      >
        {!mounted && (
          <div
            className="loading_container"
            ref={ref => (this.loadingContent = ref)}
          >
            <Loading />
          </div>
        )}

        <div ref={ref => (this.mainContent = ref)}>
          {this.state.content.about === null && <ErrorThing />}
          {this.state.content.about && <Routes />}
        </div>
      </EridenContext.Provider>
    );
  }
}

export default App;
