import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { EridenContext } from "../../App";
import { NextPage } from "../About";
import { Card } from "../About/Card";
import { gsap } from "gsap";

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export default function Services() {
  const history = useHistory();
  const [topMargins, setTopMargins] = useState([]);
  const { content } = useContext(EridenContext);
  useEffect(() => {
    if (!topMargins.length) {
      setTopMargins(
        Array(4)
          .fill()
          .map(() => {
            return getRandomArbitrary(0, 10);
          })
      );
    }
  }, [topMargins]);
  return (
    <>
      <div className="container big-section rnd-page">
        <div className="row m-y-5">
          <div className="col-lg-10 offset-lg-1">
            <h6 className="m-b-5 text-primary">{content?.rnd?.name}</h6>
            <h1>{content?.rnd?.description}</h1>
          </div>
        </div>
        <div className="m-y-5 p-y-5 card-holder">
          {content?.rnd?.services.map((card, i) => {
            return (
              <Card
                onClick={(e) => {
                  e.preventDefault();
                  const viewPort = document.querySelector(".viewport");
                  gsap.to(viewPort, {
                    duration: 1,
                    opacity: 0,
                    ease: "power4.inOut",
                    onComplete() {
                      setTimeout(() => {
                        history.push("/case-studies/" + card.link);
                        window.scrollTo(0, 0);
                        gsap.to(viewPort, {
                          duration: 1,
                          opacity: 1,
                          delay: 0,
                          ease: "power4.inOut",
                        });
                      }, 100);
                    },
                  });
                }}
                icon={require("../../assets/images/service.svg")}
                {...card}
                key={i + "card"}
              />
            );
          })}
        </div>
      </div>
      <NextPage to="/contact" name="Contact" />
    </>
  );
}
