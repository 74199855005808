import React, { createContext, Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  //Redirect,
  Link,
} from "react-router-dom";
import Intro from "./Intro";
import About from "./About";
import Navbar from "../components/Navbar";
import Services from "./Services";
import InnerServices from "./Services/innerServices";
import RND from "./RND";
import InnerCaseStudies from "./RND/innerCaseStudies";
import SmoothScroll from "../lib/SmoothScroll";
import Portfolio from "./Portfolio/index.js";
import InteractionParticles from "../components/InteractionParticles";
import InnerPortfolio from "./Portfolio/InnerPortfolio";
import Contact from "./Contact";
import { DragCursor } from "../lib/DragCursor";
import HiddenFooter from "./HiddenFooter";
import { withEridenContext } from "../lib/withEridenContext";
//import ScrollMouse from "../components/ScrollMouse";

export const ExperienceContext = createContext({});

class CatchAll extends Component {
  render() {
    return (
      <div className="forohfor" data-overlay="404">
        <h1>
          Our AI can't find <br></br>what you're looking for!
        </h1>
        <Link to="/">
          Maybe start from <br></br> the beginning?
        </Link>
      </div>
    );
  }
}

class Routes extends Component {
  state = {
    loaded: false,
    startExperience: false,
    navbar: false,
    screenCoordinates: [],
  };
  setLoaded = (value = true) => {
    this.setState({ loaded: value });
  };
  setExperience = (value = true) => {
    this.setState({ startExperience: value });
  };
  toggleNavbar = (e) => {
    this.setState({ navbar: typeof e === "boolean" ? e : !this.state.navbar });
  };

  setScreenCoordinates = (e) => {
    this.setState({ screenCoordinates: e });
  };

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <ExperienceContext.Provider
        value={{
          state: this.state,
          functions: {
            setScreenCoordinates: this.setScreenCoordinates,
            setLoaded: this.setLoaded,
            setExperience: this.setExperience,
            toggleNavbar: this.toggleNavbar,
          },
        }}
      >
        <DragCursor />
        <HiddenFooter />
        <Router>
          <InteractionParticles />
          <Navbar />
          <SmoothScroll open={this.state.navbar}>
            <Switch>
              <Route exact path="/about" component={About} />
              <Route exact path="/services" component={Services} />
              <Route
                exact
                path="/services/:component"
                component={InnerServices}
              />
              <Route exact path="/rnd" component={RND} />
              <Route
                exact
                path="/case-studies/:component"
                component={InnerCaseStudies}
              />
              <Route
                exact
                path="/portfolio/:component"
                component={InnerPortfolio}
              />
              <Route exact path="/portfolio" component={Portfolio} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/" component={Intro} />
              <Route component={CatchAll} />
            </Switch>
          </SmoothScroll>
        </Router>
      </ExperienceContext.Provider>
    );
  }
}

export default withEridenContext(Routes);
