import React, { Component } from "react";
import gsap from "gsap";
import { withExperienceContext } from "../lib/withExperienceContext";
import { withEridenContext } from "../lib/withEridenContext";

class ScrollMouse extends Component {
  scrollDown = gsap.timeline({ paused: false, repeat: -1 });
  componentDidMount() {
    window.addEventListener("scroll", this._handleScroll);
    this.scrollDown.to(this.wheel, {
      y: 200,
      ease: "power4.out",
      opacity: 0,
      duration: 2
    });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll);
  }
  componentDidUpdate(prevProps) {
    //console.log(this.props);
    if (window.innerHeight === this.props.height) {
      gsap.to(this.svg, {
        opacity: 0
      });
    }
    if (this.props.context.state.navbar !== prevProps.context.state.navbar) {
      if (this.props.context.state.navbar) {
        gsap.to(this.svg, {
          opacity: 0
        });
      } else {
        gsap.to(this.svg, {
          opacity: 1,
          delay: 3
        });
      }
    }
  }
  _handleScroll = () => {
    if (window.pageYOffset > 5) {
      gsap.to(this.svg, {
        opacity: 0
      });
    } else {
      gsap.to(this.svg, {
        opacity: 1
      });
    }
  };
  render() {
    return (
      <div
        className="scrollmouse"
        style={{ opacity: this.props.context.transitioning ? 0 : 1 }}
      >
        <svg
          width="579"
          height="823"
          viewBox="0 0 579 823"
          fill="none"
          ref={ref => (this.svg = ref)}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M289.5 0C129.867 0 0 131.908 0 294.037V528.186C0 690.315 129.867 822.215 289.5 822.215C449.124 822.215 579 690.715 579 529.086V294.037C579 131.908 449.124 0 289.5 0ZM524.548 529.086C524.548 660.695 419.111 767.764 289.5 767.764C159.897 767.764 54.4525 660.287 54.4525 528.178V294.037C54.4525 161.928 159.897 54.4509 289.5 54.4509C419.113 54.4509 524.548 161.928 524.548 294.037V529.086Z"
            fill="#d5222c"
          />
          <path
            ref={ref => (this.wheel = ref)}
            d="M289.5 225.065C274.463 225.065 262.274 237.254 262.274 252.292V347.582C262.274 362.619 274.463 374.808 289.5 374.808C304.538 374.808 316.726 362.619 316.726 347.582V252.292C316.726 237.254 304.538 225.065 289.5 225.065Z"
            fill="#d5222c"
          />
        </svg>
      </div>
    );
  }
}

export default withEridenContext(withExperienceContext(ScrollMouse));
