import React from "react";
import { withEridenContext } from "../lib/withEridenContext";
import { useHistory } from "react-router-dom";
import { gsap } from "gsap";

const ViewAll = withEridenContext(function ({ context, to, name }) {
  const history = useHistory();
  return (
    <a
      href={to}
      onClick={(e) => {
        e.preventDefault();
        if (context.transitioning) {
          return;
        }
        context.setTransitioning(true);
        const viewPort = document.querySelector(".viewport");
        gsap.to(viewPort, {
          duration: 1,
          opacity: 0,
          ease: "power4.inOut",
          onComplete() {
            setTimeout(() => {
              history.push(to);
              window.scrollTo(0, 0);
              gsap.to(viewPort, {
                duration: 1,
                opacity: 1,
                delay: 1,
                ease: "power4.inOut",
              });
              context.setTransitioning(false);
            }, 100);
          },
        });
      }}
    >
      {name}
    </a>
  );
});

export default ViewAll;
