import React from "react";
import { gsap } from "gsap";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import { withExperienceContext } from "../lib/withExperienceContext";

class HiddenFooter extends React.Component {
  tl = gsap.timeline({ paused: true });
  componentDidMount() {
    gsap.registerPlugin(MorphSVGPlugin);
    const fromPoints = this.parent.querySelectorAll(".fromPoint");
    const toPoints = this.parent.querySelectorAll("path:not(.fromPoint)");
    this.tl.from(this.viewOnHover, {
      opacity: 0,
      ease: "power4.out",
      duration: 1
    });
    toPoints.forEach(elem => {
      elem.style.visibility = "hidden";
    });
    fromPoints.forEach((fromPoint, i) => {
      this.tl.to(
        fromPoint,
        {
          morphSVG: toPoints[i],
          duration: 0.5,
          ease: "power4.inOut"
        },
        0
      );
    });
    //console.log(this.props);
  }
  render() {
    return (
      <div
        onMouseEnter={() => {
          this.tl.play();
        }}
        ref={ref => (this.viewOnHover = ref)}
        onMouseLeave={() => this.tl.reverse()}
        className="viewOnHover"
        style={{
          display: !this.props.context.state.navbar ? "none" : "block",
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: 1000,
          cursor: "pointer",
          padding: "2rem"
        }}
      >
        <a
          href="https://digitalpresent.io"
          target="_blank"
          rel="noopener noreferrer"
          alt="Made By: Digital Present"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="49"
            fill="none"
            viewBox="0 0 50 49"
            ref={ref => (this.parent = ref)}
          >
            <path
              fill="url(#paint0_linear)"
              d="M29.869 15.849H26.24l1.908 1.908.647.647 2.981 2.981v-2.713l-.268-.268-.647-.647h.915V15.85H29.87z"
            ></path>
            <path
              fill="url(#paint1_linear)"
              d="M40.878 18.073a6.297 6.297 0 00.584-1.846 11.668 11.668 0 00-2.966-.378h-6.088v2.555h.016l1.908 1.909v-2.556h4.164c.805 0 1.61.11 2.382.316z"
            ></path>
            <path
              fill="url(#paint2_linear)"
              d="M20.42 34.224h3.628l-1.924-1.908-.647-.647-2.965-2.965V31.4l.252.268.647.647h-.915l.016.016v1.892h1.908z"
            ></path>
            <path
              fill="url(#paint3_linear)"
              d="M48.276 25.044c0-3.123-1.42-5.773-3.786-7.413-.189-.126-.378-.252-.567-.363a9.091 9.091 0 00-1.83-.836 7.128 7.128 0 01-.6 1.862c-.078.204-.189.394-.3.583a8.414 8.414 0 01-1.45 1.877l-2.492 2.492 1.356 1.357 2.492-2.492a11 11 0 001.814-2.35c.11-.19.22-.38.316-.568.173.126.347.252.504.394 1.53 1.325 2.398 3.265 2.398 5.473 0 2.209-.852 4.149-2.382 5.474.316.615.568 1.261.757 1.924 2.35-1.688 3.77-4.338 3.77-7.414z"
            ></path>
            <path
              fill="url(#paint4_linear)"
              d="M34.332 29.76V26.134l-1.908 1.908-.647.647-2.981 2.981h2.713l.268-.268.647-.647v.915h1.908v-1.908z"
            ></path>
            <path
              fill="url(#paint5_linear)"
              d="M17.865 11.669c0-.805.095-1.593.3-2.366a6.268 6.268 0 00-1.846-.568 11.61 11.61 0 00-.378 2.934v6.088h2.555v-.016l1.909-1.908h-2.556l.016-4.164z"
            ></path>
            <path
              fill="url(#paint6_linear)"
              d="M15.956 31.67v.646h-4.18A8.557 8.557 0 019.412 32a6.45 6.45 0 00-.584 1.846c.962.252 1.956.378 2.95.378h6.088V31.67l-1.909-1.908v1.908z"
            ></path>
            <path
              fill="url(#paint7_linear)"
              d="M34.332 24.855v-.915l.647.647v-.016l1.34-1.356-3.895-3.896v7.823l1.908-1.924v-.363z"
            ></path>
            <path
              fill="url(#paint8_linear)"
              d="M32.424 38.404c0 .805-.095 1.593-.316 2.366a6.397 6.397 0 001.861.584c.253-.962.38-1.956.38-2.95v-6.088h-2.556v.016l-1.909 1.908h2.556l-.016 4.164z"
            ></path>
            <path
              fill="url(#paint9_linear)"
              d="M21.982 15.202l-.647.647-1.924 1.908h7.839l-1.924-1.908h-1.294l.647-.647h.016-.016l-1.34-1.357-1.357 1.357z"
            ></path>
            <path
              fill="url(#paint10_linear)"
              d="M28.086 9.098L25.61 11.59l-.458.457-1.356 1.357 1.356 1.356.442.442.016.016v-.016h2.681l-1.325-1.34-.457-.458.457-.458 2.477-2.508a8.882 8.882 0 011.892-1.45 6.384 6.384 0 00-.899-1.72 11.068 11.068 0 00-2.35 1.83z"
            ></path>
            <path
              fill="url(#paint11_linear)"
              d="M22.187 40.991l2.95-2.95 1.356-1.356-1.356-1.356-.458-.458h-2.713l1.357 1.357.252.252.22.205-.457.458-2.492 2.492a9.201 9.201 0 01-1.877 1.45c.205.616.52 1.2.9 1.72a12.255 12.255 0 002.318-1.814z"
            ></path>
            <path
              fill="url(#paint12_linear)"
              d="M36.32 26.858l.457-.457.458.457 2.492 2.493a8.878 8.878 0 011.451 1.892 5.886 5.886 0 001.704-.899 11.278 11.278 0 00-1.814-2.35l-4.29-4.306-1.357 1.357-.221.204-.237.237v2.697l1.357-1.325z"
            ></path>
            <path
              fill="url(#paint13_linear)"
              d="M13.953 23.23l-.457.458-.458-.457-2.492-2.493a9.196 9.196 0 01-1.45-1.877 6.296 6.296 0 00-1.704.9c.504.851 1.104 1.64 1.814 2.334l2.492 2.492.347.347.11.11 1.357 1.357 1.356-1.357.442-.457v-2.681l-1.357 1.325z"
            ></path>
            <path
              fill="url(#paint14_linear)"
              d="M18.764 18.404l-.252.268-.647.647v-.915h-1.909v5.536l5.521-5.536h-2.713z"
            ></path>
            <path
              fill="url(#paint15_linear)"
              d="M28.307 34.871l.646-.647 1.91-1.908h-7.824l1.924 1.908H26.24l-.646.647h.016l1.34 1.357 1.357-1.357z"
            ></path>
            <path
              fill="url(#paint16_linear)"
              d="M8.196 33.672c.11-.646.3-1.277.6-1.86.078-.19.189-.38.3-.569.41-.694.898-1.325 1.466-1.892l2.492-2.477-1.356-1.356-2.493 2.492c-.71.694-1.309 1.482-1.813 2.334-.11.19-.205.379-.3.584a11.795 11.795 0 01-.52-.41c-1.53-1.325-2.382-3.265-2.382-5.458 0-2.224.851-4.164 2.381-5.473a10.6 10.6 0 01-.757-1.924c-2.366 1.656-3.77 4.337-3.77 7.413 0 3.107 1.42 5.773 3.786 7.43.19.125.379.252.568.362.552.3 1.151.584 1.798.804z"
            ></path>
            <path
              fill="url(#paint17_linear)"
              d="M31.92 8.688c.078.205.141.41.188.615.205.773.316 1.561.3 2.366v3.533h1.924v-3.533c0-.994-.126-1.988-.378-2.95-.047-.205-.126-.426-.19-.63a9.47 9.47 0 01.663-.08c2.003-.142 3.99.615 5.537 2.177 1.545 1.562 2.334 3.549 2.192 5.552.662.205 1.293.49 1.909.82.504-2.839-.395-5.725-2.572-7.902-2.176-2.177-5.063-3.076-7.902-2.571-.22.032-.457.079-.678.142-.647.173-1.294.41-1.893.71.379.552.678 1.135.9 1.75z"
            ></path>
            <path
              fill="url(#paint18_linear)"
              d="M18.354 41.386a4.59 4.59 0 01-.19-.616 8.547 8.547 0 01-.3-2.366v-3.517h-1.908v3.517c0 .994.127 1.988.379 2.95.047.22.126.426.19.63-.222.032-.442.064-.663.08-2.004.142-3.99-.615-5.552-2.177-1.546-1.546-2.335-3.549-2.193-5.552a10.778 10.778 0 01-1.908-.836c-.49 2.84.394 5.726 2.57 7.902 2.177 2.177 5.08 3.076 7.903 2.587.237-.047.457-.094.678-.158a8.963 8.963 0 001.877-.694 8.289 8.289 0 01-.883-1.75z"
            ></path>
            <path
              fill="url(#paint19_linear)"
              d="M43.922 32.82a9.08 9.08 0 00-.694-1.892 7.15 7.15 0 01-1.719.899c-.205.079-.41.142-.63.19a8.54 8.54 0 01-2.367.299h-3.517v1.908h3.517c.994 0 1.988-.126 2.934-.378.221-.047.426-.11.631-.19.047.221.063.442.079.663.142 2.003-.615 3.99-2.177 5.552-1.561 1.562-3.533 2.303-5.552 2.177a9.105 9.105 0 01-.836 1.924c2.84.505 5.71-.394 7.902-2.587 2.193-2.192 3.076-5.063 2.572-7.902l-.142-.662z"
            ></path>
            <path
              fill="url(#paint20_linear)"
              d="M6.35 17.268c.174.647.41 1.294.71 1.893a7.15 7.15 0 011.72-.899c.205-.079.41-.142.63-.205a9.183 9.183 0 012.367-.3h3.517v-1.924h-3.518c-.993 0-1.971.126-2.933.378-.221.048-.426.127-.647.19a4.692 4.692 0 01-.079-.678c-.142-2.004.631-3.975 2.193-5.553 1.561-1.577 3.533-2.303 5.536-2.176a8.415 8.415 0 01.852-1.925c-2.84-.473-5.726.41-7.918 2.603-2.193 2.192-3.06 5.063-2.571 7.902.047.237.094.458.142.694z"
            ></path>
            <path
              fill="url(#paint21_linear)"
              d="M31.903 41.386a6.776 6.776 0 01-.583-.3 8.881 8.881 0 01-1.893-1.451l-2.476-2.492-1.357 1.356 2.492 2.492c.694.71 1.483 1.31 2.35 1.814.19.11.379.221.584.316a6.389 6.389 0 01-.41.504c-1.325 1.53-3.265 2.382-5.458 2.382-2.224 0-4.164-.852-5.489-2.382-.615.316-1.246.568-1.924.758 1.656 2.365 4.322 3.77 7.398 3.77 3.107 0 5.757-1.42 7.413-3.77.126-.19.253-.38.363-.568a9.018 9.018 0 00.852-1.862 6.67 6.67 0 01-1.862-.567z"
            ></path>
            <path
              fill="url(#paint22_linear)"
              d="M17.865 28.041v-5.094l-1.908 1.908v1.278l-.647-.631-.016-.016v.016l-1.34 1.34 1.34 1.357.663.647 1.908 1.908v-2.713z"
            ></path>
            <path
              fill="url(#paint23_linear)"
              d="M18.37 8.688a8.413 8.413 0 012.46 1.75l2.493 2.493 1.356-1.357-2.476-2.492a11.309 11.309 0 00-2.335-1.814c-.189-.11-.378-.22-.583-.316.126-.173.268-.347.41-.52C21.02 4.918 22.96 4.05 25.152 4.05c2.224 0 4.164.868 5.474 2.382a9.57 9.57 0 011.924-.757c-1.672-2.366-4.338-3.77-7.413-3.77-3.124 0-5.774 1.42-7.414 3.77-.126.19-.252.378-.363.568a9.937 9.937 0 00-.851 1.86c.646.111 1.261.316 1.86.585z"
            ></path>
            <path
              fill="url(#paint24_linear)"
              d="M5.657 16.732l-4.007-5c-.031-.047-.079-.079-.11-.11a.71.71 0 00-1.01.126.738.738 0 00.11 1.01l5.017 3.974z"
            ></path>
            <path
              fill="url(#paint25_linear)"
              d="M49.696 37.584l-5.016-3.99 3.99 5.015c.032.048.08.08.11.11a.728.728 0 00.931-1.12s0-.015-.015-.015z"
            ></path>
            <path
              fill="url(#paint26_linear)"
              d="M38.496 1.211a.708.708 0 00-.094-1.01.708.708 0 00-1.01.095l-.015.016-3.991 5.016 5.016-3.99c.031-.048.063-.08.094-.127z"
            ></path>
            <path
              fill="url(#paint27_linear)"
              d="M.136 37.3a.707.707 0 00.11 1.01.707.707 0 001.01-.11l3.99-5.017-5 4.007-.11.11z"
            ></path>
            <path
              fill="url(#paint28_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
            ></path>
            <path
              fill="url(#paint29_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint30_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint31_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint32_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint33_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint34_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint35_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint36_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint37_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint38_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint39_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint40_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint41_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint42_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint43_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint44_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint45_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <path
              fill="url(#paint46_linear)"
              d="M24.521 21.354h-2.255v7.35h2.255c2.067 0 3.486-1.42 3.486-3.675 0-2.256-1.42-3.675-3.486-3.675z"
              className="fromPoint"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="4.175"
                x2="50.114"
                y1="45.312"
                y2="-4.084"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="7.446"
                x2="53.386"
                y1="48.355"
                y2="-1.04"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="6.44"
                x2="52.382"
                y1="47.419"
                y2="-1.977"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint3_linear"
                x1="14.805"
                x2="60.745"
                y1="55.198"
                y2="5.803"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint4_linear"
                x1="12.058"
                x2="57.997"
                y1="52.643"
                y2="3.247"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint5_linear"
                x1="-4.68"
                x2="41.26"
                y1="37.077"
                y2="-12.319"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint6_linear"
                x1="3.166"
                x2="49.105"
                y1="44.373"
                y2="-5.023"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint7_linear"
                x1="8.376"
                x2="54.316"
                y1="49.219"
                y2="-0.177"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint8_linear"
                x1="15.295"
                x2="61.235"
                y1="55.654"
                y2="6.259"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint9_linear"
                x1="0.707"
                x2="46.647"
                y1="42.087"
                y2="-7.308"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint10_linear"
                x1="0.428"
                x2="46.367"
                y1="41.826"
                y2="-7.568"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint11_linear"
                x1="10.179"
                x2="56.118"
                y1="50.896"
                y2="1.501"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint12_linear"
                x1="14.136"
                x2="60.076"
                y1="54.575"
                y2="5.18"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint13_linear"
                x1="-3.523"
                x2="42.418"
                y1="38.152"
                y2="-11.243"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint14_linear"
                x1="-1.445"
                x2="44.493"
                y1="40.084"
                y2="-9.311"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint15_linear"
                x1="9.907"
                x2="55.847"
                y1="50.644"
                y2="1.249"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint16_linear"
                x1="-4.195"
                x2="41.744"
                y1="37.528"
                y2="-11.867"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint17_linear"
                x1="5.335"
                x2="51.274"
                y1="46.392"
                y2="-3.004"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint18_linear"
                x1="5.269"
                x2="51.208"
                y1="46.329"
                y2="-3.067"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint19_linear"
                x1="18.226"
                x2="64.166"
                y1="58.38"
                y2="8.984"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint20_linear"
                x1="-7.613"
                x2="38.326"
                y1="34.349"
                y2="-15.045"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint21_linear"
                x1="14.998"
                x2="60.937"
                y1="55.378"
                y2="5.983"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint22_linear"
                x1="2.233"
                x2="48.173"
                y1="43.506"
                y2="-5.89"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint23_linear"
                x1="-4.385"
                x2="41.556"
                y1="37.352"
                y2="-12.043"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint24_linear"
                x1="4.595"
                x2="-2.254"
                y1="15.596"
                y2="8.912"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#F9AD17"></stop>
              </linearGradient>
              <linearGradient
                id="paint25_linear"
                x1="44.2"
                x2="51.896"
                y1="33.338"
                y2="40.668"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#F9AD17"></stop>
              </linearGradient>
              <linearGradient
                id="paint26_linear"
                x1="32.731"
                x2="40.068"
                y1="6.292"
                y2="-1.502"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#F9AD17"></stop>
              </linearGradient>
              <linearGradient
                id="paint27_linear"
                x1="4.686"
                x2="-1.393"
                y1="33.567"
                y2="39.963"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#F9AD17"></stop>
              </linearGradient>
              <linearGradient
                id="paint28_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint29_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint30_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint31_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint32_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint33_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint34_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint35_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint36_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint37_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint38_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint39_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint40_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint41_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint42_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint43_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint44_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint45_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
              <linearGradient
                id="paint46_linear"
                x1="4.798"
                x2="50.738"
                y1="45.893"
                y2="-3.504"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BF0077"></stop>
                <stop offset="1" stopColor="#E01E42"></stop>
              </linearGradient>
            </defs>
          </svg>
        </a>
      </div>
    );
  }
}

export default withExperienceContext(HiddenFooter);
