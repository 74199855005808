import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "swiper/swiper.scss";
import "./assets/style/index.scss";

if (process.env.NODE_ENV === "production") {
  console.oldLog = console.log;
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.oldLog("We out here.");
}

ReactDOM.render(<App />, document.getElementById("eriden"));
