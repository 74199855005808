import React from "react";
import { Link } from "react-router-dom";
import { withEridenContext } from "../../../lib/withEridenContext";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import { NextPage } from "../../About";
import isMobile from "ismobilejs";

import ViewAll from "../../../components/ViewAllLink";

class InnerText extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.splitText = new SplitText(this.tagline, {
        type: "words, chars",
      });
      if (this.splitText.chars) {
        gsap.from(
          [this.name, ...this.splitText.chars, this.content, this.sideline],
          {
            opacity: 0,
            y: 20,
            ease: "power4.out",
            duration: 1,
            stagger: 0.01,
          }
        );
      }
    }
  }
  dom = [];
  render() {
    const {
      name = "",
      tagline = "",
      content = () => "",
      //sideline = "",
    } = this.props;
    return (
      <>
        <div className="container p-y-5 big-section big-section_services rnd-page">
          <div className="row align-center">
            <div className="col-lg-10 offset-lg-1">
              <h6
                className="m-b-5 text-primary "
                ref={(ref) => (this.name = ref)}
              >
                {name}​
              </h6>
              <h1 className="m-b-5" ref={(ref) => (this.tagline = ref)}>
                {tagline}
              </h1>
              <div
                ref={(ref) => (this.content = ref)}
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </div>
            {/*<div className="col-lg-5 col-margin-left">
              <h1
                ref={(ref) => (this.sideline = ref)}
                className="m-t-5">{sideline}</h1>
            </div>*/}
          </div>

          {isMobile().any && (
            <div className="row align-center">
              <div className="col-lg-12">
                <ViewAll to="/rnd" name="View all Case Studies" />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

class InnerServices extends React.Component {
  state = {
    currentText: null,
  };
  getText = async () => {
    if (this.timer) {
      this.props.context.setTimer({
        name: this.state.currentText.name.split(" ")[0].toLowerCase(),
        time: this.timer,
      });
    }
    await this.setState({
      currentText:
        this.props.context.content?.rnd?.services.filter(
          (elem) => elem.link === this.props.match.params.component
        )[0] || null,
    });
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.timer = 0;
    this.interval = setInterval(() => (this.timer = this.timer + 1), 1000);
  };
  async componentDidMount() {
    await this.getText();
  }
  async componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.component !== this.props.match.params.component
    ) {
      await this.getText();
    }
  }
  componentWillUnmount() {
    if (this.timer) {
      this.props.context.setTimer({
        name: this.state.currentText.name.split(" ")[0].toLowerCase(),
        time: this.timer,
      });
    }
  }
  render() {
    const { content } = this.props.context;
    const { currentText } = this.state;
    const appendClass = isMobile().any ? " hidethisinstantly" : "";
    return (
      <>
        <div className={`left-bar rnd-page ${appendClass}`}>
          {content?.rnd?.services.map((elem) => {
            if (elem.link) {
              return (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    gsap.to(this.section, {
                      opacity: 0,
                      ease: "power4.out",
                      onComplete: () => {
                        this.props.history.push(elem.link);
                        gsap.to(this.section, {
                          ease: "power4.out",
                          opacity: 1,
                        });
                      },
                    });
                  }}
                  key={elem.shortname}
                  className={currentText?.link === elem.link ? "active" : null}
                  to={elem.link}
                >
                  {elem.shortname}
                </Link>
              );
            }
            return null;
          })}
        </div>
        <section ref={(ref) => (this.section = ref)}>
          <InnerText history={this.props.history} {...currentText} />
        </section>
        <NextPage to="/contact" name="Contact" />
      </>
    );
  }
}

export default withEridenContext(InnerServices);
