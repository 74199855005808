import React, { Component } from "react";
import { gsap } from "gsap";
import { LinkItem } from "./LinkItem";
import { withEridenContext } from "../lib/withEridenContext";
import { withExperienceContext } from "../lib/withExperienceContext";

class NavExpandPre extends Component {
  tl = gsap.timeline({ paused: true });
  componentDidMount() {
    this.tl.from(this.navbarWrapper, {
      opacity: 0,
      display: "none",
      duration: 0.1
    });
    // this.tl.from(this.navbarItems, {
    //   opacity: 0,
    //   y: 100,
    //   duration: 1,
    //   stagger: 0.1,
    //   ease: "power4.out"
    // });
    this.tl.from(this.navbarLogo.childNodes, {
      delay: 1,
      stagger: 0.1,
      duration: 0.5,
      y: -200,
      opacity: 0,
      ease: "power4.out"
    });
    this.tl.from(this.navbarLinks.childNodes, {
      delay: 0.5,
      opacity: 0,
      display: "none",
      duration: 0.5,
      stagger: 0.1,
      ease: "power4.out"
    });
  }
  navbarItems = [];
  componentDidUpdate() {
    if (this.props.open) {
      this.tl.play();
    } else {
      this.tl.reverse();
    }
  }
  render() {
    const { history, open, toggle } = this.props;
    return (
      <div
        ref={ref => (this.navbarWrapper = ref)}
        className="navbar navbar_overlay"
      >
        <div className="logoItems" ref={ref => (this.navbarLogo = ref)}>
          <img src={require("../assets/images/logo.svg")} alt="" />
          <img src={require("../assets/images/logo_text.svg")} alt="" />
        </div>

        <ul className="navbar-nav" ref={ref => (this.navbarLinks = ref)}>
          {this.props?.context?.content?.menu?.menu?.map((link, i) => {
            return (
              <LinkItem
                disabled={!this.props.context.state.navbar}
                coordinates={this.props.context.state.screenCoordinates[i]}
                key={i + "linkItem"}
                link={link}
                i={i}
                open={open}
                pushTo={e => {
                  toggle();
                  setTimeout(() => history.push(e), 0);
                }}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

const NavExpand = withExperienceContext(withEridenContext(NavExpandPre));
export { NavExpand };
