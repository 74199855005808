import React, { Component } from "react";
import { NavExpand } from "./NavExpand";
import { withRouter } from "react-router-dom";
import { withExperienceContext } from "../lib/withExperienceContext";
import { gsap } from "gsap";
import { NavIcon } from "./LogoBefore";
import { withEridenContext } from "../lib/withEridenContext";

function bodyToggler(bool) {
  if (typeof bool === "boolean") {
    if (bool) {
      document.body.classList.add("blocker");
    } else {
      document.body.classList.remove("blocker");
    }
  } else {
    document.body.classList.toggle("blocker");
  }
}

class Navbar extends Component {
  state = {
    // scrolled: false,
    buttonDisabled: false
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.context.state.navbar !== this.props.context.state.navbar) {
      bodyToggler();
      if (this.props.context.state.navbar) {
        gsap.to(this.navbar_top, {
          y: -100,
          opacity: 0,
          duration: 1,
          ease: "power4.out"
        });
      } else {
        gsap.to(this.navbar_top, {
          y: 0,
          opacity: 1,
          duration: 1,
          delay: 1,
          ease: "power4.out"
        });
      }
    }
  }
  render() {
    const { history, context, location } = this.props;
    return (
      <div
        className={[
          "nav-overarch",
          this.state.scrolled ? "scrolled" : null
        ].join(" ")}
      >
        <NavExpand
          noExit={location.pathname === "/"}
          toggle={context.functions.toggleNavbar}
          open={context.state.navbar}
          history={history}
        />
        <nav
          className={"navbar navbar_top"}
          ref={ref => (this.navbar_top = ref)}
        >
          <div className="navbar-toggle">
            <button
              disabled={context.transitioning}
              className="burger-menu"
              onClick={() => {
                context.functions.toggleNavbar();
              }}
            >
              <NavIcon />
            </button>
          </div>
        </nav>
        <div className="navbar_spacer"></div>
      </div>
    );
  }
}

export default withEridenContext(withExperienceContext(withRouter(Navbar)));
