import React from "react";
import { gsap } from "gsap";

export class Card extends React.Component {
  openTL = gsap.timeline({ paused: true });
  componentDidMount() {
    this.openTL.to(this.mount, {
      rotationY: 180,
      duration: 1,
      ease: "power4.inOut",
    });
    this.openTL.set(
      this.front,
      {
        display: "none",
      },
      "0.5"
    );
  }
  render() {
    //
    return (
      <div
        onMouseEnter={() => this.openTL.play()}
        onMouseLeave={() => this.openTL.reverse()}
        onTouchStart={() => this.openTL.play()}
        onClick={(e) => {
          if (this.openTL.progress() === 1) {
            return typeof this.props.onClick === "function"
              ? this.props.onClick(e)
              : null;
          }
        }}
        style={
          typeof this.props.onClick === "function"
            ? { cursor: "pointer" }
            : null
        }
        className="card-single"
      >
        <div className="card-single_inner" ref={(ref) => (this.mount = ref)}>
          <div ref={(ref) => (this.front = ref)} className="card_front">
            <div className="flex-cont">
              <img
                alt="icon for card"
                ref={(ref) => (this.image = ref)}
                src={this.props.icon}
              />
            </div>
            <div className="flex-cont">
              <p>{this.props.name || this.props.title}</p>
            </div>
          </div>
          <div className="card_back">
            <div
              ref={(ref) => (this.text = ref)}
              dangerouslySetInnerHTML={{
                __html:
                  this.props.card ||
                  this.props.tagline ||
                  this.props.description,
              }}
            ></div>
            {this.props.more_button && (
              <a href={this.props.more_button}>Learn more</a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
